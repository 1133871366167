<template>
  <!--  :class="{tr90: isMobile}" -->
  <div class="container">
    <!-- <img class="image" src="../../../assets/img/data/map.png" alt="" /> -->
    <img class="image" src="./mapImg/mapBg1.jpg" alt="" />
    <!-- <div class="more" @click="back()"></div> -->
    <div class="highlight" @click.stop="closeDetail()">
      <div class="kunwushan active" @click="showDetail(1)"></div>
      <div class="xuanyuancheng active" @click="showDetail(2)"></div>
      <div class="shennong active" @click="showDetail(5)"></div>
      <div class="tanggu active" @click="showDetail(7)"></div>
      <div class="jiuli"></div>
      <div class="huanjing active" @click="showDetail(9)"></div>
      <div class="qingqiu active" @click="showDetail(4)"></div>
      <div class="yunmengze active" @click="showDetail(6)"></div>
      <div class="youqiong active" @click="showDetail(8)"></div>
      <div class="wangchuan"></div>
      <div class="donghai active" @click="showDetail(3)"></div>
      <div class="zongmen"></div>
    </div>
    <div class="detail-wrap" v-show="isShowDetail">
      <div class="detail-container">
        <div
          class="to-left"
          @click="toLeft()"
          :class="{ disabledLeft: scrollLeftDisabled }"
        ></div>
        <div class="detail-content">
          <div class="content book-content" ref="content">
            <div class="scroll-wrap">
              <img
                v-show="isImgLoad"
                @load="imgLoad"
                :src="detail.img"
                alt=""
              />
              <div class="title n2v">
                {{ detail.title }}
              </div>
              <div class="contentKeyDetail mapDetail" v-html="articleContent" ref="detailContent"></div>
            </div>
          </div>
        </div>
        <div
          class="to-right"
          @click="toRight()"
          :class="{ disabledRight: scrollRightDisabled }"
        ></div>
      </div>
    </div>
    <div
      class="mask"
      :class="{ addAnimation: isStart }"
      v-if="isShowAnimation"
      ref="mask"
    ></div>

    <!-- <div v-show="isMobile && isShowRotate" class="rotate-wrap">
      <img src="./mapImg/rotate.png" alt="" />
    </div> -->
    <!-- <canvas id="cavsElem"></canvas> -->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { isMobile, escape2Html } from "@/utils/util";
// import { getArticleList, getArticle, getChildList } from "../../api/index";
import { getArticleList, getArticle } from "@/api/index";

export default {
  nema: "Map",
  data() {
    return {
      isStart: false,
      isShowAnimation: true,
      isShowRotate: true,
      isMobile,
      imgCount: 0,
      isShowDetail: false,
      detail: {},
      isImgLoad: false,
      scrollRightDisabled: false,
      scrollLeftDisabled: false,
      imageList: [
        require("../../../assets/img/yun/0000.png"),
        // require("../../../assets/img/yun/0001.png"),
        require("../../../assets/img/yun/0002.png"),
        // require("../../../assets/img/yun/0003.png"),
        require("../../../assets/img/yun/0004.png"),
        // require("../../../assets/img/yun/0005.png"),
        require("../../../assets/img/yun/0006.png"),
        // require("../../../assets/img/yun/0007.png"),
        require("../../../assets/img/yun/0008.png"),
        // require("../../../assets/img/yun/0009.png"),
        require("../../../assets/img/yun/0010.png"),
        // require("../../../assets/img/yun/0011.png"),
        require("../../../assets/img/yun/0012.png"),
        // require("../../../assets/img/yun/0013.png"),
        require("../../../assets/img/yun/0014.png"),
        // require("../../../assets/img/yun/0015.png"),
        require("../../../assets/img/yun/0016.png"),
        // require("../../../assets/img/yun/0017.png"),
        require("../../../assets/img/yun/0018.png"),
        // require("../../../assets/img/yun/0019.png"),
        require("../../../assets/img/yun/0020.png"),
        // require("../../../assets/img/yun/0021.png"),
        require("../../../assets/img/yun/0022.png"),
        // require("../../../assets/img/yun/0023.png"),
        require("../../../assets/img/yun/0024.png"),
        // require("../../../assets/img/yun/0025.png"),
        require("../../../assets/img/yun/0026.png"),
        // require("../../../assets/img/yun/0027.png"),
        require("../../../assets/img/yun/0028.png"),
        // require("../../../assets/img/yun/0029.png"),
        require("../../../assets/img/yun/0030.png"),
        // require("../../../assets/img/yun/0031.png"),
        require("../../../assets/img/yun/0032.png"),
        // require("../../../assets/img/yun/0033.png"),
        require("../../../assets/img/yun/0034.png"),
        // require("../../../assets/img/yun/0035.png"),
        require("../../../assets/img/yun/0036.png"),
        // require("../../../assets/img/yun/0037.png"),
        require("../../../assets/img/yun/0038.png"),
        // require("../../../assets/img/yun/0039.png"),
        require("../../../assets/img/yun/0040.png"),
        // require("../../../assets/img/yun/0041.png"),
        require("../../../assets/img/yun/0042.png"),
        // require("../../../assets/img/yun/0043.png"),
        require("../../../assets/img/yun/0044.png"),
        // require("../../../assets/img/yun/0045.png"),
        require("../../../assets/img/yun/0046.png"),
        // require("../../../assets/img/yun/0047.png"),
        require("../../../assets/img/yun/0048.png"),
        // require("../../../assets/img/yun/0049.png"),
        require("../../../assets/img/yun/0050.png"),
        // require("../../../assets/img/yun/0051.png"),
        require("../../../assets/img/yun/0052.png"),
        // require("../../../assets/img/yun/0053.png"),
        require("../../../assets/img/yun/0054.png"),
        // require("../../../assets/img/yun/0055.png"),
        require("../../../assets/img/yun/0056.png"),
        // require("../../../assets/img/yun/0057.png"),
        require("../../../assets/img/yun/0058.png"),
        // require("../../../assets/img/yun/0059.png"),
        require("../../../assets/img/yun/0060.png"),
        // require("../../../assets/img/yun/0061.png"),
        require("../../../assets/img/yun/0062.png"),
        // require("../../../assets/img/yun/0063.png"),
        require("../../../assets/img/yun/0064.png"),
        // require("../../../assets/img/yun/0065.png"),
        require("../../../assets/img/yun/0066.png"),
        // require("../../../assets/img/yun/0067.png"),
        require("../../../assets/img/yun/0068.png"),
        // require("../../../assets/img/yun/0069.png"),
        require("../../../assets/img/yun/0070.png"),
        // require("../../../assets/img/yun/0071.png"),
        require("../../../assets/img/yun/0072.png"),
        // require("../../../assets/img/yun/0073.png"),
        require("../../../assets/img/yun/0074.png"),
        // require("../../../assets/img/yun/0075.png"),
        require("../../../assets/img/yun/0076.png"),
        // require("../../../assets/img/yun/0077.png"),
        require("../../../assets/img/yun/0078.png"),
        // require("../../../assets/img/yun/0079.png"),
        require("../../../assets/img/yun/0080.png"),
        // require("../../../assets/img/yun/0081.png"),
        require("../../../assets/img/yun/0082.png"),
        // require("../../../assets/img/yun/0083.png"),
        require("../../../assets/img/yun/0084.png"),
        // require("../../../assets/img/yun/0085.png"),
        require("../../../assets/img/yun/0086.png"),
        // require("../../../assets/img/yun/0087.png"),
        require("../../../assets/img/yun/0088.png"),
        // require("../../../assets/img/yun/0089.png"),
        require("../../../assets/img/yun/0090.png"),
        // require("../../../assets/img/yun/0091.png"),
        require("../../../assets/img/yun/0092.png"),
        // require("../../../assets/img/yun/0093.png"),
        require("../../../assets/img/yun/0094.png"),
        // require("../../../assets/img/yun/0095.png"),
      ],
      scrollLeft: 0,
      articleContent: "",
    };
  },
  methods: {
    ...mapMutations(["increment"]),
    back() {
      this.$router.push("/PageData");
    },
    preload() {
      const that = this;

      // var canvas = document.querySelector('#cavsElem');
      // var ctx = canvas.getContext('2d');
      // let image = new Image();
      //   image.src = this.imageList[0];
      //   image.onload = function() {
      //     that.imgCount++;
      //     ctx.drawImage(this,0,0,1920,930)
      //   };

      for (let img of this.imageList) {
        let image = new Image();
        image.src = img;
        image.onload = function () {
          that.imgCount++;
          // ctx.drawImage(this,0,0,1920,930)
        };
      }
    },
    animationDraw() {
      var canvas = document.querySelector("#cavsElem");
      var ctx = canvas.getContext("2d");
      // canvas.width = 400;
      // canvas.height = 400;
      canvas.style.border = "1px solid #000";
      var frameIndex = 0;
      draw(this.imageList[frameIndex]);
      const that = this;
      // for (let i = 0; i < 96; i++) {
      //   ctx.drawImage(this.imageList[i],0,0)
      // }
      function draw(img) {
        if (frameIndex < 96) {
          ctx.drawImage(img, 0, 0);
          frameIndex++;
          setInterval(() => {
            draw(that.imageList[frameIndex]);
          }, 20);
        } else {
          return;
        }
      }
      // setInterval(function () {
      //   ctx.clearRect(0, 0, canvas.width, canvas.height);
      //   if (frameIndex < 96) {
      //     ctx.drawImage(
      //       that.imageList[frameIndex]
      //       , 0//截取原始图片的 x坐标
      //       , 0//截取原始图片的 y坐标
      //       , 400//截取原始图片的 宽度
      //       , 400 // 截取的高度
      //       , 0//图片在canvas画布上的x坐标
      //       , 0//图片在canvas画布上的y坐标
      //       , 400
      //       , 400
      //     );
      //   }
      //   frameIndex++;
      //   // frameIndex%=4;//要求frameIndex的值小于等于四
      // }, 1000 / 20)
    },
    // showDetail(key) {
    //   console.log(key);
    // },
    async getList() {
      const list = await getArticleList({ mid: 16 });
      console.log(list);
      const detail = await getArticle({ id: 5 });
      console.log(detail);
    },
    async showDetail(id) {
      const data = await getArticle({ id });
      // this.scrollLeft = 0;
      this.$refs.content.style.transform = `translateX(0px)`;
      this.scrollRightDisabled = true;
      this.scrollLeftDisabled = false;
      if (data.data.details) {
        this.detail = data.data.details;
        this.articleContent = escape2Html(data.data.details.content);
        this.isShowDetail = true;
        const that = this;
        that.$refs.detailContent.style.display = 'flex';
        that.$refs.detailContent.style.direction = 'revert';
        // that.$refs.detailContent.style.display = 'flex';
        setTimeout(() => {
          const list = that.$refs.detailContent.children;
          if(list) {
            for (let i = 0; i < list.length; i++) {
              // console.log(i);
              const element = list[i];
              console.log(element);
              element.style.fontSize = '1.1vw';
              element.style.writingMode = 'vertical-rl';
              element.style.direction = 'initial';
              element.style.textAlign = 'left';
            }
          }
        }, 500);

      }
      // console.log(data.data.details);
    },
    closeDetail() {
      this.isShowDetail = false;
      this.scrollLeft = 0;
    },
    imgLoad() {
      // console.log("ok");
      this.isImgLoad = true;
    },
    toLeft() {
      const content = this.$refs.content;
      // console.log(content.children);
      const offsetWidth = content.children[0].offsetWidth;
      const scrollWidth = content.children[0].scrollWidth;
      const left = this.scrollLeft + 250;
      if (left + offsetWidth - scrollWidth < 250) {
        this.scrollLeft = left;
        content.style.transform = `translateX(${left}px)`;
        this.scrollRightDisabled = false;
      } else {
        this.scrollLeftDisabled = true;
      }
    },
    toRight() {
      const content = this.$refs.content;
      const left = this.scrollLeft - 250;
      if (left >= 0) {
        this.scrollLeft = left;
        content.style.transform = `translateX(${left}px)`;
        this.scrollLeftDisabled = false;
      } else {
        this.scrollRightDisabled = true;
      }
    },
    getKeyframes(name) {
      var animation = {};
      var styleSheets = document.styleSheets;
      for (var i = 0; i < styleSheets.length; i++) {
        var item = styleSheets[i];
        if (
          item.cssRules[0] &&
          item.cssRules[0].name &&
          item.cssRules[0].name == name
        ) {
          animation.cssRule = item.cssRules[0];
          animation.styleSheet = item;
          animation.index = 0;
        }
      }
      return animation;
    },
  },
  watch: {
    imgCount: function (newValue) {
      if (newValue === 45) {
        //93
        var mask = this.$refs.mask;
        let key_name='maskAnimation'
        const nameRule = this.getKeyframes(key_name);
        const arr = this.imageList;
        // console.log(arr);
        var rules='@keyframes '+key_name+'{';
        for(var i=0;i<arr.length;i++){
            rules += `${i*2}%{background-image: url(${arr[i]});}`
        }
        rules+='}';
        if(JSON.stringify(nameRule)=='{}'){
            var sheet=document.styleSheets[0];
            sheet.insertRule(rules,0);
        }else{
            nameRule.styleSheet.deleteRule(nameRule.index)
            nameRule.styleSheet.insertRule(rules,nameRule.index)
        }
        console.log(nameRule);

        mask.style.animation= key_name+' 2s';
        this.isStart = true;
        const that = this;
        setTimeout(() => {
          that.isShowAnimation = false;
        }, 1900);
        // this.animationDraw();
        // console.log(1);
      }
    },
  },
  mounted() {
    this.increment({ status: 10 });
    if (this.isMobile) {
      this.isShowAnimation = false;
      const that = this;
      window.onorientationchange = function () {
        switch (window.orientation) {
          case -90:
          case 90:
            that.isShowRotate = false;
            break;
          case 0:
          case 180:
            that.isShowRotate = true;
            break;
        }
      };
    } else {
      this.preload();
    }

    // this.getList();
  },
  destroyed() {
    this.increment({ status: 1 });
  },
};
</script>


<style lang="scss" scoped>
// @media (max-width: 750px) {
//   .image {
//     width: 100%;
//     height: 100%;
//   }
// }

// @media (min-width: 751px) {
.image {
  // width: 1920px;
  // height: 937px;
  width: 100vw;
  height: 100vh;
}

.highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.highlight > div {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  // background-size: cover;
  background-size: 82% 82%;
  // opacity: 0;
}
.highlight > .active:hover {
  // opacity: 1;
  // transition: 1s;
  // background-size: cover;
  animation: mapIamgesScale 1s;
  animation-fill-mode: forwards;
}

@keyframes mapIamgesScale {
  0%{
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

// .highlight > div::after {
//   content: "";
//   display: none;
//   // opacity: 0;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   box-shadow: 0px 0px 20px 20px #edea93a1;
// }


.kunwushan {
  width: 11.5vw;
  height: 19vh;
  top: 0;
  left: 6.823vw;
  background-image: url(./mapImg/1.png);
  transform-origin: top;
}
.kunwushan:hover {
  background-image: url(./mapImg/1-4.png);
}
.xuanyuancheng {
  width: 11.302vw;
  height: 21.064vh;
  top: 12.234vh;
  left: 19.7vw;
  background-image: url(./mapImg/3.png);
  transform-origin: bottom;
}
.xuanyuancheng:hover {
  background-image: url(./mapImg/3-1.png);
}
.shennong {
  width: 6.031vw;
  height: 19.34vh;
  top: 5.819vh;
  left: 49vw;
  background-image: url(./mapImg/6.png);
  transform-origin: center;
}
.shennong:hover {
  background-image: url(./mapImg/6-1.png);
}
.tanggu {
  width: 4.219vw;
  height: 14.941vh;
  top: 16.25vh;
  left: 61.792vw;
  background-image: url(./mapImg/7.png);
  transform-origin: bottom;
}
.tanggu:hover {
  background-image: url(./mapImg/7-1.png);
}
.jiuli {
  // width: 122px;
  width: 6.354vw;
  // height: 156px;
  height: 16.649vh;
  // top: 273px;
  top: 29.136vh;
  // left: 624px;
  left: 32.5vw;
  background-image: url(./mapImg/4.png);
  transform-origin: center;
}
.huanjing {
  width: 7.135vw;
  height: 21.131vh;
  top: 5.8vh;
  left: 80.719vw;
  background-image: url(./mapImg/9.png);
  transform-origin: center;
}
.huanjing:hover {
  background-image: url(./mapImg/9-1.png);
}
.qingqiu {
  width: 9.365vw;
  height: 16.261vh;
  top: 50.033vh;
  left: 42.677vw;
  background-image: url(./mapImg/13.png);
  transform-origin: bottom;
  // background-size: 204px 196px;
  // background-size: 7.625vw 7.208vw;
}
.qingqiu:hover {
  background-image: url(./mapImg/13-1.png);
}
.yunmengze {
  width: 10.365vw;
  height: 26vh;
  top: 13.033vh;
  left: 2.677vw;
  background-image: url(./mapImg/8.png);
  transform-origin: center;
}
.yunmengze:hover {
  background-image: url(./mapImg/8-1.png);
}
.youqiong {
  width: 7.76vw;
  height: 25.4vh;
  top: 41.678vh;
  left: 35.844vw;
  background-image: url(./mapImg/12.png);
  transform-origin: bottom;
}
.youqiong:hover {
  background-image: url(./mapImg/12-1.png);
}
.wangchuan {
  // width: 105px;
  width: 5.469vw;
  // height: 157px;
  height: 16.756vh;
  // top: 719px;
  top: 76.734vh;
  // left: 127px;
  left: 6.615vw;
  background-image: url(./mapImg/10.png);
  transform-origin: bottom;
}
.donghai {
  width: 9.365vw;
  height: 19.823vh;
  top: 69.375vh;
  left: 49.6vw;
  background-image: url(./mapImg/11.png);
  transform-origin: center;
}
.donghai:hover {
  background-image: url(./mapImg/11-1.png);
}



.detail-wrap {
  width: 1108px;
  height: 604px;
  background: url(../../../assets/img/data/juanzhou2.png);
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.detail-container {
  display: flex;
  align-items: center;
  height: 100%;
  .detail-content {
    flex: 1;
    width: 896px;
    // height: 460px;
    overflow: hidden;
    // padding-left: 20px;
  }
  .to-left,
  .to-right {
    width: 65px;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .to-left {
    background-image: url(../../../assets/img/data/left.png);
    background-position: center right;
    margin-left: 35px;
  }
  .to-right {
    background-image: url(../../../assets/img/data/right.png);
    background-position: center left;
    margin-right: 35px;
  }
  .disabledLeft {
    background: url(../../../assets/img/data/disabled.png) no-repeat;
    background-position: center right;
    background-size: contain;
  }
  .disabledRight {
    background: url(../../../assets/img/data/disabled.png) no-repeat;
    background-position: center right;
    background-size: contain;
    transform: rotate(180deg);
  }
}

.book-content {
  transition-duration: 2s;
  .scroll-wrap {
    display: flex;
    align-items: center;
    height: 462px;
    letter-spacing: 4px;
    line-height: 26px;
    color: #4f2d08;
    direction: rtl;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
    img {
      height: 100%;
      min-width: 800px;
    }
    .n2v {
      font-size: 16px;
    }
    .title {
      margin-left: 30px;
      line-height: 1.5;
      width: 30px;
      font-size: 28px;
      // writing-mode: vertical-rl;
    }
  }
}
.contentKeyDetail {
  display: flex;
  height: 100%;
  // padding: 5px 0;
  // font-size: 16px;
  padding-top: 8px;
}
</style>


<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  // background: url(../../../assets/img/data/map.png) no-repeat;
  // background-size: cover;
  position: relative;
  .more {
    position: absolute;
    top: 21px;
    left: 30px;
    width: 86px;
    height: 36px;
    background: url(../../../assets/img/data/more.png) no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../assets/img/yun/0000.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  /* 动画代码 */
  @keyframes example {
    0% {
      background-image: url(../../../assets/img/yun/0000.png);
    }
    // 1% {
    //   background-image: url(../../../assets/img/yun/0001.png);
    // }
    2% {
      background-image: url(../../../assets/img/yun/0002.png);
    }
    // 3% {
    //   background-image: url(../../../assets/img/yun/0003.png);
    // }
    4% {
      background-image: url(../../../assets/img/yun/0004.png);
    }
    // 5% {
    //   background-image: url(../../../assets/img/yun/0005.png);
    // }
    6% {
      background-image: url(../../../assets/img/yun/0006.png);
    }
    // 7% {
    //   background-image: url(../../../assets/img/yun/0007.png);
    // }
    8% {
      background-image: url(../../../assets/img/yun/0008.png);
    }
    // 9% {
    //   background-image: url(../../../assets/img/yun/0009.png);
    // }
    10% {
      background-image: url(../../../assets/img/yun/0010.png);
    }
    // 11% {
    //   background-image: url(../../../assets/img/yun/0011.png);
    // }
    12% {
      background-image: url(../../../assets/img/yun/0012.png);
    }
    // 13% {
    //   background-image: url(../../../assets/img/yun/0013.png);
    // }
    14% {
      background-image: url(../../../assets/img/yun/0014.png);
    }
    // 15% {
    //   background-image: url(../../../assets/img/yun/0015.png);
    // }
    16% {
      background-image: url(../../../assets/img/yun/0016.png);
    }
    // 17% {
    //   background-image: url(../../../assets/img/yun/0017.png);
    // }
    18% {
      background-image: url(../../../assets/img/yun/0018.png);
    }
    // 19% {
    //   background-image: url(../../../assets/img/yun/0019.png);
    // }
    20% {
      background-image: url(../../../assets/img/yun/0020.png);
    }
    // 21% {
    //   background-image: url(../../../assets/img/yun/0021.png);
    // }
    22% {
      background-image: url(../../../assets/img/yun/0022.png);
    }
    // 23% {
    //   background-image: url(../../../assets/img/yun/0023.png);
    // }
    24% {
      background-image: url(../../../assets/img/yun/0024.png);
    }
    // 25% {
    //   background-image: url(../../../assets/img/yun/0025.png);
    // }
    26% {
      background-image: url(../../../assets/img/yun/0026.png);
    }
    // 27% {
    //   background-image: url(../../../assets/img/yun/0027.png);
    // }
    28% {
      background-image: url(../../../assets/img/yun/0028.png);
    }
    // 29% {
    //   background-image: url(../../../assets/img/yun/0028.png);
    // }
    30% {
      background-image: url(../../../assets/img/yun/0030.png);
    }
    // 31% {
    //   background-image: url(../../../assets/img/yun/0031.png);
    // }
    32% {
      background-image: url(../../../assets/img/yun/0032.png);
    }
    // 33% {
    //   background-image: url(../../../assets/img/yun/0033.png);
    // }
    34% {
      background-image: url(../../../assets/img/yun/0034.png);
    }
    // 35% {
    //   background-image: url(../../../assets/img/yun/0035.png);
    // }
    36% {
      background-image: url(../../../assets/img/yun/0036.png);
    }
    // 37% {
    //   background-image: url(../../../assets/img/yun/0037.png);
    // }
    38% {
      background-image: url(../../../assets/img/yun/0038.png);
    }
    // 39% {
    //   background-image: url(../../../assets/img/yun/0039.png);
    // }
    40% {
      background-image: url(../../../assets/img/yun/0040.png);
    }
    // 41% {
    //   background-image: url(../../../assets/img/yun/0041.png);
    // }
    42% {
      background-image: url(../../../assets/img/yun/0042.png);
    }
    // 43% {
    //   background-image: url(../../../assets/img/yun/0043.png);
    // }
    44% {
      background-image: url(../../../assets/img/yun/0044.png);
    }
    // 45% {
    //   background-image: url(../../../assets/img/yun/0045.png);
    // }
    46% {
      background-image: url(../../../assets/img/yun/0046.png);
    }
    // 47% {
    //   background-image: url(../../../assets/img/yun/0047.png);
    // }
    48% {
      background-image: url(../../../assets/img/yun/0048.png);
    }
    // 49% {
    //   background-image: url(../../../assets/img/yun/0049.png);
    // }
    50% {
      background-image: url(../../../assets/img/yun/0050.png);
    }
    // 51% {
    //   background-image: url(../../../assets/img/yun/0051.png);
    // }
    52% {
      background-image: url(../../../assets/img/yun/0052.png);
    }
    // 53% {
    //   background-image: url(../../../assets/img/yun/0053.png);
    // }
    54% {
      background-image: url(../../../assets/img/yun/0054.png);
    }
    // 55% {
    //   background-image: url(../../../assets/img/yun/0055.png);
    // }
    56% {
      background-image: url(../../../assets/img/yun/0056.png);
    }
    // 57% {
    //   background-image: url(../../../assets/img/yun/0057.png);
    // }
    58% {
      background-image: url(../../../assets/img/yun/0058.png);
    }
    // 59% {
    //   background-image: url(../../../assets/img/yun/0059.png);
    // }
    60% {
      background-image: url(../../../assets/img/yun/0060.png);
    }
    // 61% {
    //   background-image: url(../../../assets/img/yun/0061.png);
    // }
    62% {
      background-image: url(../../../assets/img/yun/0062.png);
    }
    // 63% {
    //   background-image: url(../../../assets/img/yun/0063.png);
    // }
    64% {
      background-image: url(../../../assets/img/yun/0064.png);
    }
    // 65% {
    //   background-image: url(../../../assets/img/yun/0065.png);
    // }
    66% {
      background-image: url(../../../assets/img/yun/0066.png);
    }
    // 67% {
    //   background-image: url(../../../assets/img/yun/0067.png);
    // }
    68% {
      background-image: url(../../../assets/img/yun/0068.png);
    }
    // 69% {
    //   background-image: url(../../../assets/img/yun/0069.png);
    // }
    70% {
      background-image: url(../../../assets/img/yun/0070.png);
    }
    // 71% {
    //   background-image: url(../../../assets/img/yun/0071.png);
    // }
    72% {
      background-image: url(../../../assets/img/yun/0072.png);
    }
    // 73% {
    //   background-image: url(../../../assets/img/yun/0073.png);
    // }
    74% {
      background-image: url(../../../assets/img/yun/0074.png);
    }
    // 75% {
    //   background-image: url(../../../assets/img/yun/0075.png);
    // }
    76% {
      background-image: url(../../../assets/img/yun/0076.png);
    }
    // 77% {
    //   background-image: url(../../../assets/img/yun/0077.png);
    // }
    78% {
      background-image: url(../../../assets/img/yun/0078.png);
    }
    // 79% {
    //   background-image: url(../../../assets/img/yun/0079.png);
    // }
    80% {
      background-image: url(../../../assets/img/yun/0080.png);
    }
    // 81% {
    //   background-image: url(../../../assets/img/yun/0081.png);
    // }
    82% {
      background-image: url(../../../assets/img/yun/0082.png);
    }
    // 83% {
    //   background-image: url(../../../assets/img/yun/0083.png);
    // }
    84% {
      background-image: url(../../../assets/img/yun/0084.png);
    }
    // 85% {
    //   background-image: url(../../../assets/img/yun/0085.png);
    // }
    86% {
      background-image: url(../../../assets/img/yun/0086.png);
    }
    // 87% {
    //   background-image: url(../../../assets/img/yun/0087.png);
    // }
    88% {
      background-image: url(../../../assets/img/yun/0088.png);
    }
    // 89% {
    //   background-image: url(../../../assets/img/yun/0089.png);
    // }
    90% {
      background-image: url(../../../assets/img/yun/0090.png);
    }
    92% {
      background-image: url(../../../assets/img/yun/0091.png);
    }
    94% {
      background-image: url(../../../assets/img/yun/0092.png);
    }
    96% {
      background-image: url(../../../assets/img/yun/0093.png);
    }
    98% {
      background-image: url(../../../assets/img/yun/0094.png);
    }
    100% {
      background-image: url(../../../assets/img/yun/0095.png);
    }
  }
  .addAnimation {
    // animation-name: example;
    // animation-duration: 4s;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.img-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.tr90 {
  transform: rotate(90deg);
}
.rotate-wrap {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.7);
  img {
    width: 2rem;
    height: 2rem;
    // transform: rotate(90deg);
    animation: phoneRotate 2s linear infinite;
  }
}
@keyframes phoneRotate {
  0% {
    transform: rotate(-35deg);
  }
  50% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(-35deg);
  }
}
#cavsElem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
</style>